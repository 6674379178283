<template>
  <v-dialog
    :value="show"
    :max-width="$dialog.large"
    no-click-animation
    persistent
    eager
  >
    <v-card v-if="block" class="pa-5">
      <v-card-title>
        <h5 class="font-weight-bold text-h5">Block Codes for "{{ block.name }}"</h5>

        <v-btn
          @click="$emit('close')"
          absolute
          right
          top
          icon
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p class="body-1 grey--text mb-7">Copy the code below to implement it in the actual website build.</p>

        <!-- ORIENTATIONS -->
        <v-btn-toggle v-model="orientation" class="mr-2" dark mandatory>
           <v-btn
            v-if="block.orientations.includes('left')"
            @click="setCodeByOrientation('left')"
            value="left"
            small
            icon
           >
             <v-icon x-small>mdi-format-align-left</v-icon>
           </v-btn>

           <v-btn
            v-if="block.orientations.includes('center')"
            @click="setCodeByOrientation('center')"
            value="center"
            small
            icon
           >
             <v-icon x-small>mdi-format-align-center</v-icon>
           </v-btn>

           <v-btn
            v-if="block.orientations.includes('right')"
            @click="setCodeByOrientation('right')"
            value="right"
            small
            icon
           >
             <v-icon x-small>mdi-format-align-right</v-icon>
           </v-btn>
        </v-btn-toggle>

        <!-- COLOR -->
        <v-btn-toggle v-model="color" mandatory>
           <v-btn
            v-if="checkColor('dark')"
            @click="setCodeByColor('dark')"
            value="dark"
            small
            icon
           >
             <v-icon x-small color="primary">mdi-brightness-1</v-icon>
           </v-btn>

           <v-btn
            v-if="checkColor('grey')"
            @click="setCodeByColor('grey')"
            value="grey"
            small
            icon
           >
             <v-icon x-small color="grey">mdi-brightness-1</v-icon>
           </v-btn>

           <v-btn
            v-if="checkColor('light')"
            @click="setCodeByColor('light')"
            value="light"
            small
            icon
           >
            <v-icon x-small color="white">mdi-brightness-1</v-icon>
           </v-btn>
        </v-btn-toggle>

        <v-row class="mt-5">
          <!-- SHORTCODE -->

          <v-col>
            <div class="d-flex justify-space-between align-center mb-5">
              <span class="font-weight-bold">
                Shortcode
              </span>

              <v-btn
                v-clipboard:copy="shortcode"
                v-clipboard:success="onCopy"
                small class="primary--text"
              >
                <v-icon left small>mdi-content-copy</v-icon>
                Copy
              </v-btn>
            </div>

            <v-card color="#2d2d2d" min-height="500" max-height="500">
              <v-card-text class="pa-5">
                <pre class="language-none" v-html="shortcode"></pre>
              </v-card-text>
            </v-card>

          </v-col>

          <!-- CSS -->
          <v-col>
            <div class="d-flex justify-space-between align-center mb-5">
              <span class="font-weight-bold">
                CSS Code
              </span>

              <v-btn
                v-clipboard:copy="cssCode"
                v-clipboard:success="onCopy"
                small class="primary--text"
              >
                <v-icon left small>mdi-content-copy</v-icon>
                Copy
              </v-btn>
            </div>


            <v-card color="#2d2d2d" min-height="500" max-height="500">
              <v-card-text class="pa-5">
                <pre class="language-css" v-html="css"></pre>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState } from 'vuex'
import _ from 'lodash'
import Prism from 'prismjs'

export default {
  props: {
    show: Boolean,
    block: Object
  },

  data() {
    return {
      color: null,
      orientation: null,
      cssCode: null,
      shortcode: null,
      orientations: [
        'left',
        'center',
        'right',
      ],
      colors: [
        'dark',
        'grey',
        'light'
      ]
    }
  },

  watch: {
    'block': function (block) {
      if (block) {
        this.setInitials()
      }
    },
  },

  computed: {
    ...mapState({
      svgs: state => state.blocks.svgs
    }),

    css: function () {
      return Prism.highlight(this.cssCode, Prism.languages.css, 'css')
    },
  },

  methods: {
    checkColor(color) {
      if (this.block) {
        return (this.block[_.camelCase(`${this.orientation}-${color}-img`)]) ? true : false
      }
    },

    setInitials() {
      this.orientation = null
      this.color = null

      this.block.orientations.forEach(orientation => {
        if (!this.orientation) {
          this.orientation = orientation

          this.colors.forEach(color => {
            if (this.block[_.camelCase(`${orientation}-${color}-img`)] && !this.color) {
               this.color = color
               this.cssCode = this.block[_.camelCase(`${orientation}-${color}-css`)] ? this.block[_.camelCase(`${orientation}-${color}-css`)] : ''
               this.shortcode = this.block[_.camelCase(`${orientation}-${color}-shortcode`)] ? this.block[_.camelCase(`${orientation}-${color}-shortcode`)] : ''
            }
          })
        }
      })
    },

    setCodeByColor(color) {
      this.color = color
      this.cssCode = this.block[_.camelCase(`${this.orientation}-${color}-css`)] ? this.block[_.camelCase(`${this.orientation}-${color}-css`)] : ''
      this.shortcode = this.block[_.camelCase(`${this.orientation}-${color}-shortcode`)] ? this.block[_.camelCase(`${this.orientation}-${color}-shortcode`)] : ''
    },

    setCodeByOrientation(orientation) {
      this.orientation = orientation

      this.colors.forEach(color => {
        if (this.block[_.camelCase(`${orientation}-${color}-img`)] && !this.color) {
           this.color = color
           this.cssCode = this.block[_.camelCase(`${orientation}-${color}-css`)] ? this.block[_.camelCase(`${orientation}-${color}-css`)] : ''
           this.shortcode = this.block[_.camelCase(`${orientation}-${color}-shortcode`)] ? this.block[_.camelCase(`${orientation}-${color}-shortcode`)] : ''
        }
      })
    },

    onCopy: function (e) {
      if (e) {
        this.$store.dispatch('showSuccess', 'Copied to clipboard')
      }
    }
  },
}

</script>
